import React, { useEffect, useState } from 'react'
import reg from '../assets/images/Registration.jpg'
import microsite from '../assets/images/Microsite.png'
import ldexample from '../assets/images/ldexample.png'
import parttimers from '../assets/images/parttimers.jpeg'
import emailblast from '../assets/images/emailblast.png'
import {
  CheckCircleIcon,
  GiftIcon,
  EnvelopeIcon,
  UserGroupIcon,
  CogIcon,
  CreditCardIcon
} from "@heroicons/react/24/solid";
import { Typography, Card, Button,   CardBody,
  CardFooter, } from '@material-tailwind/react';
import { Slide, Fade } from "react-awesome-reveal";
import { useSpringCarousel } from 'react-spring-carousel'

const Services = () => {



  return (
    <div id="services" className='py-32 bg-gray-100'>
      <Typography variant='h1' className='text-center'>SERVICES</Typography>
      <Slide triggerOnce direction='left' duration={1500}>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-10 lg:max-w-7xl mx-auto'>
        <div className='col-span-1 pt-20 lg:pl-10 lg:py-20'>
          <img src={microsite} className="imgtest img-shadow mx-auto servicesImage rounded-2xl" alt="registration"/>
        </div>
        <div className='col-span-1 lg:pr-10 px-10 lg:pt-20 mx-auto'>
          <CreditCardIcon className='h-12 w-12'/>
          <Typography variant='h4' className='py-4'>
            Microsite
          </Typography>
          <Typography variant='lead'>
          We provide a customised microsite that will portray the details of your event in an aesthetically pleasing yet coherent manner. It also acts as an entry point for gathering the information of potential participants through a registration page
          </Typography>
          <ul className='ml-4 py-4 list-disc'>
            <li><Typography variant='small'>Extremely customisable</Typography></li>
            <li><Typography variant='small'>Dynamic and responsive</Typography></li>
            <li><Typography variant='small'>Effective way to share information</Typography></li>
          </ul>
       </div>
      </div>
      </Slide>
      <Slide triggerOnce direction='right' duration={1500}>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-10 lg:max-w-7xl mx-auto'>
        <div className='order-last lg:order-1 col-span-1 lg:pr-10 px-10 lg:pt-20 lg:mt-2 mx-auto'>
          <EnvelopeIcon className='h-12 w-12'/>
          <Typography variant='h4' className='py-4'>
            Email Blast
          </Typography>
          <Typography variant='lead'>
          Unlocks a swift way to reach a huge multitude of guests via their email. Guests will also be able to receive invites that are unique and specifically addressed to them.
          </Typography>
          <ul className='ml-4 py-4 list-disc'>
            <li><Typography variant='small'>Unique email domain</Typography></li>
            <li><Typography variant='small'>Reach international audiences</Typography></li>
            <li><Typography variant='small'>Real time status updates</Typography></li>
          </ul>
       </div>
       <div className='order-1 lg:order-last col-span-1 lg:mr-10 pt-20 lg:pl-10 lg:py-20'>
          <img src={emailblast} className="imgtest img-shadow mx-auto servicesImage rounded-2xl" alt="registration"/>
        </div>
      </div>
      </Slide>
      <Slide triggerOnce direction='left' duration={1500}>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 lg:max-w-7xl mx-auto'>
      <div className='col-span-1 pt-10 lg:mr-10 lg:py-20'>
          <img src={reg} className="imgtest img-shadow lg:ml-10 mx-auto servicesImage rounded-2xl" alt="registration"/>
        </div>
      <div className='col-span-1 px-10 lg:pl-10 lg:py-20 mx-auto'>
          <CheckCircleIcon className='h-12 w-12'/>
          <Typography variant='h4' className='py-4'>
            Registration System
          </Typography>
          <Typography variant='lead' className='lg:w-full'>
          We supply professional registration counters that are interconnected and tallies the number and information of attendees with precision. You can check on status updates, search for a guest's attendance, and more at any time.
          </Typography>
          <ul className='ml-4 py-4 list-disc'>
            <li><Typography variant='small'>Fast and efficient</Typography></li>
            <li><Typography variant='small'>Intuitive and user friendly</Typography></li>
            <li><Typography variant='small'>Easily accessible information</Typography></li>
          </ul>
       </div>
      </div>
      </Slide>
      <Slide triggerOnce direction='right' duration={1500}>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-10 lg:max-w-7xl mx-auto'>
        <div className='order-last lg:order-1 col-span-1 px-10 lg:pt-20 mx-auto'>
          <GiftIcon className='h-12 w-12'/>
          <Typography variant='h4' className='py-4'>
          Lucky Draw System 
          </Typography>
          <Typography variant='lead'>
          We present a modern and hassle-free way of conducting your lucky draws. The system seamlessly links with the attendance collected at registration and follows a logical order of operation. Moreover, we provide the ability to handle collection of prizes on the spot if required. 
          </Typography>
          <ul className='ml-4 py-4 list-disc'>
          <li><Typography variant='small'>Exciting and positive suspense</Typography></li>
            <li><Typography variant='small'>Effortlessly record winners</Typography></li>
            <li><Typography variant='small'>Truly randomised</Typography></li>
          </ul>
       </div>
       <div className='order-1 lg:order-last col-span-1 pt-20 lg:pr-10 lg:py-20'>
          <img src={ldexample} className="imgtest mx-auto img-shadow servicesImage rounded-2xl" alt="registration"/>
        </div>
      </div>
      </Slide>
      <Slide triggerOnce direction='left' duration={1500}>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 lg:max-w-7xl mx-auto'>
      <div className='col-span-1 pt-10 lg:pl-10 lg:py-20'>
          <img src={parttimers} className="imgtest img-shadow mx-auto servicesImage rounded-2xl" alt="registration"/>
        </div>
      <div className='lg:order-1 col-span-1 px-10 lg:pl-10 lg:py-20 lg:pt-12 mx-auto'>
          <UserGroupIcon className='h-12 w-12'/>
          <Typography variant='h4' className='py-4'>
          Manpower & Manpower Management
          </Typography>
          <Typography variant='lead' className='lg:w-full'>
          Our manpower manager oversees the planning, coordination, and implementation of manpower, ensuring smooth experiences for both clients and participants alike. This goes hand in hand with our available allocation of experienced and enthusiastic workers for your event's needs. From conferences, dinner and dances, family days, road shows and many more.
          </Typography>
          <Typography variant='h5' className='py-2'>
          Stage Hands, Event Coordinators, ICs or Managers available
          </Typography>
          <ul className='ml-4 pb-4 list-disc'>
            <li><Typography variant='small'>Adept and reliable</Typography></li>
            <li><Typography variant='small'>Problem solvers</Typography></li>
            <li><Typography variant='small'>Ensure smooth event processes</Typography></li>
          </ul>
       </div>
      </div>
      </Slide>
      <Fade triggerOnce duration={2000}>
      <Card className='mt-20 p-10 mx-auto bg-gray-900 w-11/12 md:w-10/12 lg:w-9/12'>
        <div className='py-4 flex'>
        <CreditCardIcon color="white" className="h-6 w-6 mr-4"/><Typography color='white' variant='h6'>Sample Microsite<br/><span className='font-normal'>Want to see an example of a comprehensive  microsite that we provide?</span><br/><a target="_blank" href="https://rs-microsite-sample.com/"><Button className="w-32 mt-4" size='md' color='blue'>Click here!</Button></a></Typography>
        </div>
      </Card>
      </Fade>
    </div>
  )
}

export default Services